<template>
<div id="resumeDetail">
  <el-dialog  lock-scroll @close="closeDialog" :visible.sync="resumeDialog" width="1200px"  v-loading="loading">
    <div class="close-btn" @click="closeDialog"><i class="el-icon-close"></i></div>
    <div class="resume-left">
      <div class="base-info">
        <div class="avator">
            <img :src="resumeInfo.recruitUserInfo.headSculpture" />
        </div>
        <div class="base-mid">
            <h3>
              <label>{{resumeInfo.recruitUserInfo.userName}}</label>
            </h3>
            <p>
                <img width="17px" height="17px" src="@/assets/imgs/personal/birday.png" /><span>{{resumeInfo.recruitUserInfo.age}}岁<em>|</em></span>
                <img v-show="resumeInfo.resumeBasicInfo.areaName" width="13px" height="16px" src="@/assets/imgs/location-icon.png" /><span  v-show="resumeInfo.resumeBasicInfo.areaName">{{resumeInfo.resumeBasicInfo.areaName}}<em>|</em></span>
                <img width="15px" height="16px" src="@/assets/imgs/edu-icon.png" /><span>{{resumeInfo.resumeBasicInfo.educationName}}<em>|</em></span>
                <img width="16px" height="16px" src="@/assets/imgs/time-icon.png" /><span>{{resumeInfo.resumeBasicInfo.workExperienceName}}工作经验<em>|</em></span>
                <img width="17px" height="17px" src="@/assets/imgs/personal/re-status.png" /><span>{{resumeInfo.resumeJobDesire.jobStatusName}}</span>
              </p>
            <h4>
                <img width="16px" height="17px" src="@/assets/imgs/personal/hope.png" />
                <span>求职期望：{{resumeInfo.resumeJobDesire.workFunctionName}}<em>|</em></span>
                <span v-show="resumeInfo.resumeBasicInfo.areaName">{{resumeInfo.resumeBasicInfo.areaName}}<em>|</em></span>
                <span>
                  {{resumeInfo.resumeJobDesire.minimumWage}}<label v-show="resumeInfo.resumeJobDesire.maximumSalary">-{{resumeInfo.resumeJobDesire.maximumSalary}}</label>
                 </span>
            </h4>
        </div>
      </div>
      <div class="online">
         <span>在线简历</span>
      </div>
      <div class="resume-info">
         <div class="experience">
             <div class="ex-title">个人优势</div>
             <div class="ex-item-wrap"> 
                <div class="skill-item" v-for="(item,index) in resumeInfo.resumeProfessionalSkillsList" :key="index">
                    {{item.skillName}}
                </div>
                <div class="ex-des">
                   {{resumeInfo.resumeOtherInfo.selfEvaluation}}
                </div>
             </div>
         </div>
        <div class="experience">
          <div class="ex-title">工作经历：</div>
          <div class="ex-item-wrap"> 
            <div class="ex-item" v-for="(item,index) in resumeInfo.resumeWorkHistoryList" :key="index">
              <div class="ex-info">
                  <img src="@/assets/imgs/company/company-logo.png" />
                   <h5>
                       <span>{{item.unitName}}</span>
                       <label> {{item.positionName}}</label>
                   </h5>
              </div>
              <strong>{{item.entryTime}} 至 {{item.leavedate}}</strong>
              <p>{{item.jobDescription}}</p>
            </div>
          </div>
        </div>
        <div class="experience">
          <div class="ex-title">项目经历：</div>
          <div class="ex-item-wrap"> 
            <div class="ex-item" v-for="(item,index) in resumeInfo.resumeProjectExperienceList" :key="index">
              <label> {{item.projectName}}</label>
              <strong>{{item.startTime}} 至 {{item.endTime}}</strong>
              <p>{{item.projectDescription}}</p>
            </div>
          </div>
        </div>
        <div class="experience">
          <div class="ex-title">教育经历：</div>
          <div class="ex-item-wrap"> 
            <div class="ex-item" v-for="(item,index) in resumeInfo.resumeEducationList" :key="index">
              <div class="ex-info">
                  <img src="@/assets/imgs/company/company-logo.png" />
                   <h5>
                       <span>{{item.schoolName}}</span>
                       <label> {{item.major}} / {{item.highestEducationName}}</label>
                   </h5>
              </div>
              <strong>{{item.admissionTime}} 至 {{item.departureTime}}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="resume-right">
        <div class="call-box">
           <h3>觉得对方还不错，可打招呼沟通：</h3>
           <p>
             <i v-show="resumeInfo.favoriteUsers==0" @click="collectionResume" class="el-icon-star-off"></i>
             <i v-show="resumeInfo.favoriteUsers==1" @click="cancelFavorite" class="el-icon-star-off star-on"></i>
             <span>收藏</span>
           </p>
           <p>
            <img src="@/assets/imgs/company/down.png" />
            <span>下载附件简历</span>
           </p>
           <div class="call-btn" @click="jumpChat">打招呼</div>
        </div>
    </div>
  </el-dialog>

</div>
</template>

<script>
import { getResumeInfo,favoriteUsers ,cancelFavorite,addEnterpriseBrowsingUsers} from "@/api/index.js";
export default {
  name: "resumeDetail",
  props: {
    resumeDialog: {
      default: false,
    },
    userId: {
      default: '',
    },
  },
   watch: {
    userId: {
      immediate: true,
      handler(val) {
        if(val){
           this.getResumeInfo()
        }
      }
    }
  },
  data() {
    return {
      loading:true,
      resumeInfo: {
        recruitUserInfo: {},
        resumeBasicInfo: {},
        resumeJobDesire:{},
        resumeOtherInfo:{},
        resumeWorkHistoryList:[],
        userInfo:{}
      },

    }
  },
  methods: {
    addEnterpriseBrowsingUsers(){
      addEnterpriseBrowsingUsers({
         userId: this.resumeInfo.recruitUserInfo.id
      })
    },
    collectionResume(){
      favoriteUsers({
        userId: this.userId
      }).then(res=>{
        if(res.code==200){
           this.$message({
            message: '收藏成功',
            type: 'success'
           })
           this.getResumeInfo()
        }
      })
    },
    cancelFavorite(){
      cancelFavorite(this.resumeInfo.collectId).then(res=>{
        if(res.code==200){
           this.$message({
            message: '取消收藏成功',
            type: 'success'
           })
           this.getResumeInfo()
        }
      })
    },
    jumpChat(){
      this.$router.push({
        path: '/chat',
        query: {
          recvId:this.resumeInfo.recruitUserInfo.id
        }
      })
    },
    closeDialog(){
      this.$emit('closeDialog')
    },
    getResumeInfo() {
      this.loading = true
      getResumeInfo({
        userInfoId: this.userId
      }).then(res => {
         this.loading = false
        var info = res.data
        if (info.recruitUserInfo.dateOfBirth) {
          info.recruitUserInfo.age = new Date().getFullYear() - info.recruitUserInfo.dateOfBirth.split('-')[0]
        }
        this.resumeInfo = info
        this.addEnterpriseBrowsingUsers()
      })
    }
  }
};
</script>

<style lang="scss" scoped>
  .close-btn{
      position: absolute;
      right: -65px;
      top:20px;
      font-size:45px;
      color: #fff;
      cursor: pointer;
      z-index:999;
    }
  .resume-left{
    float: left;
    width: 790px;
    padding: 0 25px;
    height: calc(100vh - 35px) !important;
    overflow: auto;
  }
  .resume-right{
    float: right;
    width: 330px;
    background: rgb(247, 248, 250);
    padding: 28px 15px;
   position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
  .call-box{
    padding: 20px 16px;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    border: 1px solid rgb(232, 233, 235);
    margin-bottom: 16px;
    overflow: hidden;
    h3{
      font-size: 14px;
    font-weight: 400;
    color: rgb(102, 102, 102);
    }
    p{
      float: left;
      margin: 20px 20px;
      cursor: pointer;
      text-align: center;
      img{
        width: 20px;
        height: 20px;
      }
       i{
        color: #999;
        font-size:23px;
        position: relative;
        top: -2px;
        cursor: pointer;
      }
      .star-on{
        color: #0077FF;
      }
      span{
        display: block;
        font-size: 12px;
        color: rgb(130, 141, 155);
      }
    }
    .call-btn{
      clear: both;
      width: 100%;
      height: 40px;
      line-height: 40px;
      margin: 16px 0px 0px;
      border-radius: 2px;
      text-align: center;
      border: 1px solid #0077FF;
      cursor: pointer;
      background: #0077FF;
      color: rgb(255, 255, 255);
    }
  }
  .base-info{
    padding-top: 40px;
    overflow: hidden;
    .avator{
      float: left;
      img{
          width: 60px;
      height: 60px;
      border-radius: 100%;
      cursor: pointer;
      }
    }
    .base-mid{
      float: left;
      margin-left: 27px;
      h3{
        width:670px;
        font-weight: 500;
        label{
          font-size: 20px;
          font-weight: 600;
          color: #222;
          line-height: 20px;
          letter-spacing: 1px;
        }
      }
       p{
        height: 20px;
        margin-top: 12px;
          display:block;
          img{
            margin-right:15px;
            position: relative;
            top: 2px;
          }
          span{
            display: inline-block;
             font-size: 14px;
            color: rgb(51, 51, 51);
          }
        }
      h4{
        height: 20px;
        margin-top: 12px;
        span{
          font-weight: 100;
          color: #222;
          font-size: 14px;
        }
        img{
          margin-right:15px;
              position: relative;
              top: 2px;
        }
      }
       em{
        display: inline-block;
        color: rgb(232, 233, 235) ;
        font-style: normal;
        margin-right: 15px;
          margin-left: 15px;
       }
    }
  }
  .online{
    border-bottom: 1px solid rgb(241, 241, 241);
    height: 43px;
    line-height: 43px;
    font-size: 14px;
    color: #0077FF;
    margin-top: 15px;
    span{
      width: 58px;
      text-align: center;
      display: inline-block;
      height: 41px;
      border-bottom: 2px solid #0077FF;
    }
  }
  .resume-info{
    position: relative;
    overflow: hidden;
    margin: 27px 0;
  }
  .resume-info::before {
    content: " ";
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 4px;
    background: #0077FF;
    opacity: 0.05;
  }
  .experience{
    clear: both;
    overflow: hidden;
    margin-bottom: 15px;
    .ex-item-wrap{
      float: left;
      margin-left: 16px;
    }
    .skill-item{
        border: 1px solid #eaedec;
      font-size: 12px;
      color: #999;
      height: 22px;
      line-height: 22px;
      padding:0 9px;
      display: inline-block;
      border-radius: 12px;
      margin-right: 9px;
    }
    .ex-des{
    word-break: break-all;
    font-size: 14px;
    line-height: 30px;
    font-weight: 400;
    margin-top: 10px;
    color: rgb(102, 102, 102);
    letter-spacing: 1px;
    }
    .line{
      font-style: normal;
      color: #e0e0e0;
      display: inline-block;
      margin: 0 14px;
    }
    .ex-title{
      border-left: 3px solid #0077FF;
      padding-left: 16px;
     margin-bottom: 20px;
     height: 24px;
     line-height: 24px;
      font-size: 16px;
      font-weight: 600;
      font-family: PingFangSC-Semibold, "PingFang SC";
    }
    .ex-item{
     width: 770px;
      margin-bottom: 22px;
      overflow: hidden;
      
      .ex-info{
        width: 400px;
        float: left;
        img{
         width: 44px;
         height: 44px;
         float: left;
        }
       h5{
          float: left;
          font-weight: 100;
          margin-left: 10px;
        }
        span{
          color: #333;
          font-size: 14px;
        }
        label{
          display: block;
          font-size: 14px;
          color: #333;
          margin-top:8px;
          font-weight: 100;
        }
      }
      span{
       font-size: 14px;
        color: #333;
        font-weight: 600;
      }
      label{
        font-weight: 600;
      }
      strong{
        display: block;
        float: right;
        font-weight: 500;
        color: #9fa3b0;
        font-size: 14px;
        position: relative;
        top: -1px;
      }
      p{
        font-size: 14px;
        padding-top: 10px;
        line-height: 30px;
        color: #666666;
        letter-spacing: 1px;
        clear: both;
        white-space:pre-wrap;
      }
    }
  }
</style>
<style lang="scss">
#resumeDetail{
   .el-dialog{
        margin-top: 10px !important;
    position: absolute;
    left: 50%;
    margin-left: -600px;
    top: 10px;
    bottom: -35px;
   }
   .el-dialog__header{
    display: none;
   }
   .el-dialog__wrapper{
     overflow-y: hidden;
   }
  .el-dialog__body{
    box-sizing: border-box;
    overflow-x: hidden;
     color: #414a60;
     overflow-y: auto;
     padding: 0;
    // height: 640px;
    overflow: auto;
  }
}
</style>